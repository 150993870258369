/** @jsx jsx */
import { jsx } from 'theme-ui'

export const Card = props => (
  <div
    {...props}
    sx={{
      variant: 'styles.Card',
    }}
  />
)

export default Card
